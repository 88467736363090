import React, { DetailedHTMLProps, FC } from "react";

interface Props
  extends DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  label?: string;
  className?: string;
}

const TextArea: FC<Props> = (props) => {
  return (
    <div className={"flex flex-col w-full"}>
      {props.label && (
        <label htmlFor={props.id}>
          {props.label}
          <span className="text-primary">
            {props.required ? "*" : " (Optional)"}
          </span>
        </label>
      )}
      <textarea
        {...props}
        className={`border border-black rounded p-3 ${props.className}`}
      />
    </div>
  );
};

export default TextArea;
