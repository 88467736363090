import React, { FC, useEffect, useState } from "react";
import { getShippingMethods } from "../../services/woocommerceServices/Checkout";
import { ShippingMethod } from "../../types";
import Checkbox from "../common/InputFields/Checkbox";
import RadioInput from "../common/InputFields/RadioInput";
import { useDispatch, useSelector } from "react-redux";
import { removeShippingMethod, updateShippingMethod } from "../../store/cartSlice";
import { rootReducer, RootState } from "../../store";
import ErrorMsg from "../common/ErrorMsg";

interface Props {
	shippingMethodError: string;
	setError: React.Dispatch<React.SetStateAction<string>>;
}

const MIN_AMOUNT_FOR_FREE_SHIPPING = parseFloat("900");

const ShippingMethods: FC<Props> = ({ shippingMethodError, setError }) => {
	const dispatch = useDispatch();
	const methodChecked: Partial<ShippingMethod> = useSelector(
		(state: rootReducer) => state.cart.shippingMethod
	);
	// const [minAmountForFreeShipping, setMinAmountForFreeShipping] = useState<string | undefined>("");
	const { subTotal, total, SubTotalWithCoupon } = useSelector((state: RootState) => state.cart)
	const [shippingMethod, setShippingMethod] = useState<ShippingMethod[]>([]);

	const handleChange = (method: ShippingMethod) => {
		const obj: any = {};
		setError("")

		obj["method_id"] = method.method_id;
		obj["method_title"] = method.method_title;
		obj["total"] = method.settings?.cost?.value || "0.00";

		dispatch(updateShippingMethod(obj));
	};

	useEffect(() => {
		getShippingMethods()
			.then((res: any) => {
				setShippingMethod(res.data);
			})
			.catch((err) => {
				// console.error(err, "<<-- Error in getting shipping methods");
			});
	}, []);


	useEffect(() => {
		const freeShipping = shippingMethod.filter((item: ShippingMethod) => item.method_id === "free_shipping");
		const minAmountForFreeShipping = freeShipping?.[0]?.settings.min_amount?.value;

		if (parseFloat(SubTotalWithCoupon) <= parseFloat(minAmountForFreeShipping as string)) {
			// console.log("flat rate block called")
			let obj: any = {};

			const method = shippingMethod.filter((item: ShippingMethod) => item.method_id === "flat_rate");

			obj["method_id"] = method?.[0]?.method_id;
			obj["method_title"] = method?.[0]?.method_title;
			obj["total"] = method?.[0]?.settings?.cost?.value || "0.00";

			dispatch(updateShippingMethod(obj));

		} else {
			// console.log("Free shipping called")
			let obj: any = {};

			const method = shippingMethod.filter((item: ShippingMethod) => item.method_id === "free_shipping");

			obj["method_id"] = method?.[0]?.method_id;
			obj["method_title"] = method?.[0]?.method_title;
			obj["total"] = method?.[0]?.settings?.cost?.value || "0.00";

			dispatch(updateShippingMethod(obj));
		}
	}, [shippingMethod, SubTotalWithCoupon])

	return (
		<div className="mt-6">
			{/* <h2 className="font-sofiaBold font-bold md:text-[28px] text-[25px]  mb-[25px] relative flex items-center justify-between">
				Shipping Methods
				<ErrorMsg value={shippingMethodError} />
			</h2>
			{shippingMethod.map((method) => {
				return (
					<RadioInput
						key={method.id}
						disabled={method.method_id === "free_shipping" && parseFloat(subTotal) < MIN_AMOUNT_FOR_FREE_SHIPPING}
						label={method.title}
						id={method.method_id}
						name={"shipping_method"}
						value={method?.method_title}
						defaultChecked={method.method_id === "flat_rate"}
						checked={method?.method_title === methodChecked?.method_title}
						description={method.method_description}
						price={method?.settings?.cost?.value}
						onChange={() => handleChange(method)}
						className={`mb-4 border bg-bg-grey rounded-[6px] mt-[7px] py-4 px-4 disabled:cursor-not-allowed`}
					/>
				);
			})} */}
			<></>
		</div>
	);
};

export default ShippingMethods;
